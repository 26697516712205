import * as pub from '$env/static/public';
import { dev } from '$app/environment';

export const site = {
    name       : pub.PUBLIC_SITE_NAME,
    domain     : pub.PUBLIC_SITE_DOMAIN,
    theme_color: `#${pub.PUBLIC_SITE_THEME_COLOR || '000000'}`,
};

export const paths = {
    objects: '/export/json/objects.json',
    sets   : '/export/json/sets.json',
};