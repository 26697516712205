import { writable } from 'svelte/store';

const EXPIRE = 3000;
let id = 0;

function createNotifStore() {
    const notifs = writable([]);

    function dismiss(notif) {
        clearTimeout(notif.expire);
        notifs.update(a => a.filter(a => a !== notif));
    }

    function send(msg, type = 'default', expire = EXPIRE) {
        notifs.update(arr => {
            const notif = {
                id: id++,
                msg,
                type,
                expire,
            };

            notif.dismiss = () => dismiss(notif);
            notif.timeout = setTimeout(() => dismiss(notif), expire);

            arr.push(notif);
            return arr;
        });
    }

    const { subscribe } = notifs;

    return {
        subscribe,
        dismiss,
        send,

        default: (msg, expire) => send(msg, 'default', expire),
        error:   (msg, expire) => send(msg, 'error',   expire),
        info:    (msg, expire) => send(msg, 'info',    expire),
        success: (msg, expire) => send(msg, 'success', expire),
    };
}

const notif = createNotifStore();

export default notif;
